<template>
  <b-col
    md="6"
    class="my-3"
  >
    <b-card class="h-100 ">
      <b-card-title>{{ event.name }}</b-card-title>
      <b-card-sub-title v-if="event.cancelled">
        <b-badge variant="danger">
          Zrušené
        </b-badge>
      </b-card-sub-title>

      <b-card-body class="px-0 pb-0">
        <p v-if="event.description">
          {{ event.description }}
        </p>
        <b-row align-v="center">
          <b-col>
            <font-awesome-icon
              :icon="['fas', 'calendar-alt']"
              class="mr-2"
            /> {{ date }}
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col lg="6">
            <font-awesome-icon
              :icon="['fas', 'map-marker']"
              class="mr-2"
            /> {{ places }}
          </b-col>
          <b-col lg="6">
            <font-awesome-icon
              :icon="['fas', 'info-circle']"
              class="mr-2"
            /> {{ type }}
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col>
            <font-awesome-icon
              :icon="['fas', 'users']"
              class="mr-2"
            /> {{ contestants }}
          </b-col>
          <b-col>
            <font-awesome-icon
              :icon="['fas', 'book']"
              class="mr-2"
            /> {{ sciences }}
          </b-col>
        </b-row>
        <b-row align-v="center">
          <b-col>
            <font-awesome-icon
              :icon="['fas', 'globe']"
              class="mr-2"
            />
            <a
              :href="event.link"
              target="_blank"
            >Webová stránka</a>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-col>
</template>

<script>
import { utils } from 'frontend-common'

const CONSTANTS = {
  types: {
    'sutaz': 'súťaž',
    'seminar': 'seminár',
    'sustredenie': 'sústredenie',
    'vikendovka': 'víkendovka',
    'tabor': 'tábor',
    'olympiada': 'olympiáda',
    'prednasky': 'prednášky',
    'other': 'iné',
  },
  contestant_types: {
    'zs': 'ZŠ',
    'ss': 'SŠ',
  },
  sciences: {
    'mat': 'MAT',
    'fyz': 'FYZ',
    'inf': 'INF',
    'other': 'iné',
  },
}

const fmt_contestant = (contestant, prev_contestant) => {
  if (prev_contestant && prev_contestant.substr(0, 2) === contestant.substr(0, 2))
    return contestant.substr(2)
  return `${CONSTANTS.contestant_types[contestant.substr(0, 2)]} ${contestant.substr(2)}`
}

export default {
  name: 'CalendarEvent',
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    date() {
      let output = utils.dateToString(this.event.date.start)
      if (this.event.date.end) {
        output += ` - ${utils.dateToString(this.event.date.end)}`
      }
      return output
    },
    places() {
      return this.event.places.join(', ')
    },
    contestants() {
      const event = this.event
      if (!event.contestants.min && !event.contestants.max)
        return 'ktokoľvek'

      if (!event.contestants.min && event.contestants.max)
        return `${fmt_contestant(event.contestants.max)} a mladší`

      if (event.contestants.min && !event.contestants.max)
        return `${fmt_contestant(event.contestants.min)} a starší`

      if (event.contestants.min === event.contestants.max)
        return fmt_contestant(event.contestants.min)

      return `${fmt_contestant(event.contestants.min)} – ` +
        fmt_contestant(event.contestants.max, event.contestants.min)
    },
    sciences() {
      return this.event.sciences.map((x) => CONSTANTS.sciences[x]).join(', ')
    },
    type() {
      return CONSTANTS.types[this.event.type]
    },
  },
}
</script>
